.backToTop{
    position: fixed; 
    z-index: 1100;
   width: 100%;
   /* left: 95%;
   bottom: 75px; 
    height: 20px; */
   /* font-size: 3rem; */
  
   cursor: pointer;
   color: rgb(59 130 246 / 1); 
}